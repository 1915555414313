import { Container, Paper, Stack } from "@mui/material";
import React from "react";

interface IProps {
  children: React.ReactNode;
}

export default function ({ children }: IProps) {
  return (
    <>
      <Stack
        sx={() => ({
          minHeight: "100svh",
          width: "100svw",
        })}
        alignItems="center"
        justifyContent="center"
      >
        <Paper
          sx={(theme) => ({
            minHeight: "100svh",
            width: "100svw",
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.up("sm")]: {
              overflow: "scroll",
              minHeight: "716px",
              maxHeight: "716px",
              width: "475px",
            },
            [theme.breakpoints.up("md")]: {
              width: "705px",
            },
          })}
        >
          <Container maxWidth={"xs"}>{children}</Container>
        </Paper>
      </Stack>
    </>
  );
}
